<template>
<div class="mainform">
      <div class="mainHeader">
      发布招标项目
      <span @click="back" class="back">返回></span>
    </div>
  <div class="form">
    <div class="header">基本信息</div>
    <bid-info ref="bascform" :formData="formData" :dataFormat="dataFormat" @dataAction="dataAction" class="content bascform"></bid-info>
    <div class="header">评标标准</div>
    <evaluation-criteria ref="criteria" :formData="formData" :dataFormat="dataFormat" @dataAction="dataAction" class="content tableform"></evaluation-criteria>
    <div class="header">评标小组</div>
    <bid-evaluation-team ref="evaluationteam" :formData="formData" :dataFormat="dataFormat" @dataAction="dataAction" class="content tableform"></bid-evaluation-team>
    <div class="header">供应商</div>
    <inviting-suppliers ref="suppliers" :formData="formData" :dataFormat="dataFormat" @dataAction="dataAction" class="content tableform"></inviting-suppliers>
    <div class="action" v-if="haveButton">
      <el-button @click="save" class="save" type="primary" size="medium">保存</el-button>
      <el-button  @click="submit" class="submit" type="primary" size="medium">发布</el-button>
    </div>
  </div>
</div>
</template>

<script>
import { formData, dataFormat } from './js/bidConfig'
import Component from '@/assets/js/components.js'
import { request, deepClone } from '@/assets/js/http.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  components: Component.components,
  name: 'BidForm',
  data: function () {
    FlowCtlData.docid = ''
    var id = this.$route.query.id
    var formdata = deepClone(formData)
    FlowCtlData.flowid = 'bid'
    if (id) {
      request(`/api/bid/getById/${id}`, 'post', {}).then((response) => {
        if (response.code === '200') {
          formdata = response.data
          this.formData = response.data
          if (this.formData.status === 2) {
            this.haveButton = false
          }
        }
      })
      FlowCtlData.docid = id
      FlowCtlData.initialize()
    }
    return {
      formData: formdata,
      dataFormat: dataFormat,
      haveButton: true
    }
  },
  created () {
    var id = this.$route.query.id
    FlowCtlData.docid = id
    FlowCtlData.initialize()
  },
  watch: {
    $route: {
      handler () {
        var id = this.$route.query.id
        if (id) {
          request(`/api/bid/getById/${id}`, 'post', {}).then((response) => {
            if (response.code === '200') {
              this.formData = response.data
              if (this.formData.status === 2) {
                this.haveButton = false
              }
            }
          })
        }
        FlowCtlData.docid = id
        FlowCtlData.initialize()
      }
    }
  },
  methods: {
    back: () => {
      window.history.back()
    },
    dataAction: function (data) {
      this.formData = data
    },
    save: function () {
      for (var d of this.formData.bidSupplierList) {
        delete d.id
      }
      this.formData.status = 0
      request('/api/bid/save', 'post', this.formData).then((response) => {
        if (response.code === '200') {
          FlowCtlData.docid = response.data
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push({ query: { id: response.data } })
        }
        this.dialogShow = false
      })
    },
    submit: async function () {
      var isPass = true
      this.$refs.bascform.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      this.$refs.criteria.$refs.EvaluationCriteriaForm.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      this.$refs.evaluationteam.$refs.BidEvaluationTeamForm.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      this.$refs.suppliers.$refs.choiceSupplier.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      for (var d of this.formData.bidSupplierList) {
        delete d.id
      }
      this.formData.status = 2
      this.formData.supplierPsn = ''
      const SupplierIds = []
      for (const item of this.formData.bidSupplierList) {
        SupplierIds.push(item.code)
      }
      await request('/api/system/user/getUserListBySupplierId', 'post', SupplierIds).then((response) => {
        if (response.code === '200') {
          for (const item of response.data) {
            this.formData.supplierPsn = this.formData.supplierPsn + item.username + ';'
          }
        }
      })
      this.formData.bhct = 'n'
      FlowCtlData.getNodeAction('TJ', this.formData).then(
        (val) => {
          if (val) {
            this.$confirm('下一环节：' + FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeName + '。是否提交？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              FlowCtlData.setFormData(FlowCtlData, this.formData)
              request('/api/bid/save', 'post', this.formData).then((response) => {
                if (response.code === '200') {
                  FlowCtlData.docid = response.data
                  FlowCtlData.setNodeAction().then((val) => {
                    if (val) {
                      this.$message({
                        message: '成功',
                        type: 'success'
                      })
                      this.dialogShow = false
                      window.history.back()
                    }
                  })
                }
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消提交'
              })
            })
          }
        }
      )
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  .action{
    margin-bottom: 20px;
  }
</style>
