export var formData = {
  bidCode: '',
  bidEndDate: '',
  bidEndTime: '',
  bidEvaluationEndTime: '',
  bidEvaluationStartTime: '',
  bidExpertList: [
    {
      bidExpertMessageList: [
      ],
      deptCode: '',
      deptName: '',
      deptWeight: null,
      departmentOptions: [],
      participantOptions: [],
      departmentLoading: false,
      participanLoading: false
    }
  ],
  bidFileList: [
  ],
  bidMaterialMap: '',
  bidMaterialPriceMap: {},
  bidMinDesc: '',
  bidMinPrice: null,
  bidScoringList: [
  ],
  bidStartDate: '',
  bidStartTime: '',
  bidSupplierList: [
  ],
  businessScope: '',
  companyCode: '',
  companyName: '',
  createDate: '',
  createUserId: '',
  createUserName: '',
  evaluationBidCode: '',
  itemName: '',
  modifyDate: '',
  paymentDays: '',
  quoteList: [
  ],
  releaseEndTime: '',
  releaseStartTime: '',
  status: 0,
  titleList: []
}

var bidTeam = (rule, value, callback) => {
  var isPass = false
  let weight = 0
  for (var item of value) {
    weight = weight + parseFloat(item.deptWeight)
    if (item.deptWeight === null || item.deptWeight === '' || item.deptCode === '' || item.bidExpertMessageList.length < 1) {
      isPass = true
      break
    }
  }
  if (isPass) {
    return callback(new Error('请将评分信息填写完整'))
  }
  if (weight > 100) {
    return callback(new Error('部门权重总和必须小于等于一百'))
  }
}

export var dataFormat = {
  formDisabled: false,
  fieldShow: {
    bidCode: false,
    createUserName: false,
    companyName: false
  },
  rules: {
    itemName: [
      { required: true, message: '请输入项目名称', trigger: 'blur' }
    ],
    businessScope: [
      { required: true, message: '请选择经营范围', trigger: 'blur' }
    ],
    companyCode: [
      { required: true, message: '请选择公司代码', trigger: 'blur' }
    ],
    paymentDays: [
      { required: true, message: '请输入账期', trigger: 'change' }
    ],
    bidEndDate: [
      { required: true, message: '请输入应标截止日期', trigger: 'change' }
    ],
    bidStartDate: [
      { required: true, message: '请输入开标日期', trigger: 'change' }
    ],
    bidMinPrice: [
      {
        validator: (rule, value, callback) => {
          if (value && value !== '') {
            console.log('value' + value)
            if (!/^\d+(\.\d+)?$/.test(value)) {
              return callback(new Error('请输入数字'))
            }
          }
        },
        trigger: 'blur'
      }
    ],
    titleList: [
      { required: true, message: '请选择报价指标', trigger: 'blur' }
    ],
    bidFileList: [
      { required: true, message: '请选择招标文件', trigger: 'blur' }
    ],
    bidMaterialMap: [
      { required: true, message: '请上传明细项', trigger: 'blur' }
    ],
    bidScoringList: [
      { required: true, message: '请上传评标标准', trigger: 'blur' }
    ],
    bidEvaluationStartTime: [
      { required: true, message: '请选择评标日期', trigger: 'blur' }
    ],
    bidEvaluationEndTime: [
      { required: true, message: '请选择评标日期', trigger: 'blur' }
    ],
    bidExpertList: [
      { validator: bidTeam, trigger: 'blur' }
    ],
    bidSupplierList: [
      { required: true, message: '请选择供应商', trigger: 'blur' }
    ]
  }
}
